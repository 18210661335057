
import { defineComponent, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import router from "@/router";
import { ElNotification } from "element-plus";
import { ElLoading } from 'element-plus'

export default defineComponent({
  name: "ticket",
  mixins: [Globals],
  components: {},

  mounted() {
    this.showById();
  },

  data() {
    return {
      ticketData: [],
      extra: [],
      loading: false,
      newComment: "",
      userIssueId: "" as any,
      allComments: [] as any,
      allAttachments: [] as any,
      srcURL: [] as any,
      selectedFiles: [],
      uploadedFiles: [],
      requestBodyAttachment: {
        application: "",
        organizationType: this.getOrgRole(),
        organizationId: this.globalData.organizationId,
        data: {
          orgId: this.globalData.organizationId,
          emailId: this.globalData.email,
          fileName: "",
        },
      },
      requestBodyExtraAttachment: {
        application: "",
        organizationType: this.getOrgRole(),
        organizationId: this.globalData.organizationId,
        data: {
          orgId: this.globalData.organizationId,
          emailId: this.globalData.email,
          attachments: [],
        },
      },
      commentReq: {
        application: "",
        organizationType: this.getOrgRole(),
        organizationId: this.globalData.organizationId,
        data: {
          comment: {
            name: "",
            email: "",
            timestamp: "",
            text: "",
            image: "",
          },
        },
      },
    };
  },
  methods: {
    handleFileChange(event) {
    
      
      

      
      if (event.target.files[0].size > 5000000)
      {
         ElNotification({
               title: "Error",
               message: "Please choose file less than 5mb",
               type: "error",
               position: "bottom-right",
             });
       }
       else
       {
        //  this.selectedFiles = event.target.files[0];
         this.selectedFiles = event.target.files;
       }
      //  for (var i = 0; i <event.target.files.length; i++)
      // {
      //   // console.log('size',event.target.files[i].size)
      //   if (event.target.files[i].size > 5000000)
      // {
      //   ElNotification({
      //         title: "Error",
      //         message: "Please choose file less than 5mb",
      //         type: "error",
      //         position: "bottom-right",
      //       });
      // }
      // else
      // {
      //   this.selectedFiles = event.target.files[i];
      // }


      // }
      
      // console.log('Selected file',this.selectedFiles)
    },

    async uploadToS3(url, obj) {
      fetch(url, {
        method: "PUT",
        headers: {
          "x-amz-acl": "bucket-owner-full-control",
          "Content-Type": "multipart/form-data",
        },
        body: obj,
      });
    },

    async uploadFiles() {
      this.loading=true
      if (this.selectedFiles == undefined) {
        return;
      } 



      if (this.selectedFiles == null)
      {
        return;
      }
      if (this.selectedFiles.length < 0)
      {
        return ;
      }
      var fileStatus = true;
      this.loading = true;

      for (var file of this.selectedFiles) {
        this.requestBodyAttachment.data.fileName = file["name"];
        await ApiService.post(
          "/support/ticket/attachments",
          this.requestBodyAttachment
        )
          .then(async ({ data }) => {
            this.uploadedFiles.push(data.data.path as never);
            await this.uploadToS3(data.data.url, file);
          })
          .catch((error) => {
            this.loading = false;
            fileStatus = false;
            // console.log("error in getting url");
          });
      }

      if (fileStatus == true) {
        this.requestBodyExtraAttachment.data.attachments = this.uploadedFiles;
        await ApiService.post(
          "/support/ticket/attachments/" + this.userIssueId,
          this.requestBodyExtraAttachment
        )
          .then((res) => {
            this.loading = false;
            this.selectedFiles = [];
            ElNotification({
              title: "Success",
              message: "File uploaded!",
              type: "success",
              position: "top-right",
            });
            window.location.reload();
          })
          .catch((error) => {
            this.loading = false;
            ElNotification({
              title: "Error",
              message: "Upload Failed!",
              type: "error",
              position: "top-right",
            });
        
          });
      }
    },

    addComment() {
      if (this.newComment == "") return;

      this.commentReq.data.comment.name = this.extra["displayName"];
      this.commentReq.data.comment.email = this.extra["emailAddress"];
      this.commentReq.data.comment.text = this.newComment;

      ApiService.post(
        "support/ticket/" + this.userIssueId + "/comment",
        this.commentReq
      )
        .then((data) => {
          this.newComment = "";
          ElNotification({
            title: "Success",
            message: "New Comment added!",
            type: "success",
            position: "top-right",
          });
          window.location.reload();
        })
        .catch(() => {
          // console.log("catch in comment");
        });
    },

    displayData(data) {
      this.ticketData = JSON.parse(JSON.stringify(data));
      this.extra = this.ticketData["data"][0];

      for (var i = 0; i < this.ticketData["data"][0]["comments"].length; i++) {
        this.allComments.push(this.ticketData["data"][0]["comments"][i]);
      }

      for (var j = 0; j < this.ticketData["data"][0]["attachments"].length;j++) {
        this.allAttachments.push(this.ticketData["data"][0]["attachments"][j]);
      }

      for (var k = 0; k < this.allAttachments.length; k++) {
        this.srcURL.push(this.allAttachments[k].url);
      }
    },

    showById() {
      const route = useRoute();
      this.userIssueId = route.params.ticketId;
  
      if (this.userIssueId == undefined) {
        router.push("/404");
        return;
      }
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(
        "/support/ticket?issueKey=" +
          this.userIssueId +
          "&orgId=" +
          this.globalData.organizationId
      )
        .then((data) => {
          loading.close()
          this.displayData(data.data);
        })
        .catch(() => {
          loading.close()
          router.push("/404");
        });
    },
  },
  setup() {
    onMounted(() => {
      const route = useRoute();
      let title = "Ticket : " + route.params.ticketId;
      setCurrentPageBreadcrumbs(title, ["Support Center"]);
    });
  },
});
